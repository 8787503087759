<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      no-filters
      vertical-scroll
      @filterOption="onFiltersChange"
    >
      <template v-slot:job_id="{ item }">
        <div>
          <div class="d-flex gap">
            <div
              class="open-link"
              @click="editItem(item)"
            >
              {{ item.job_id }}
            </div>
            <CopyContent :content="item.job_id" />
          </div>
        </div>
        <!--  -->
      </template>
      <template v-slot:map="{ item }">
        <div class="open-link">
          <router-link :to="goToMapScreen(item)">
            <v-icon>mdi-map</v-icon>
          </router-link>
        </div>
      </template>
      <template v-slot:image_shipment_id="{ item }">
        <div>
          <div
            v-if="item.image_shipment_id"
            class="d-flex gap"
          >
            <div
              class="open-link"
              @click="showImage(item)"
            >
              {{ item.image_shipment_id }}
            </div>
            <CopyContent :content="item.image_shipment_id" />
          </div>
          <div v-else>
            N/A
          </div>
        </div>
        <!--  -->
      </template>
      <template v-slot:stop_duration="{ item }">
        <div v-if="item.stop_duration">
          {{ item.stop_duration }}
          <span style="font-size: 10px">(MIN)</span>
        </div>
      </template>
      <template v-slot:completed_at="{ item }">
        <div v-if="item.completed_at">
          {{ item.completed_at | formatDate }}
        </div>
      </template>
      <template v-slot:job_notes="{ item }">
        <div class="fixed-text-box">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
              >
                {{ item.job_notes }}
              </div>
            </template>
            <span>{{ item.job_notes }}</span>
          </v-tooltip>
        </div>
      </template>

      <!-- add Filters -->
      <template #addFilters>
        <v-card>
          <select-date
            v-model="customFilters.date"
            filled
            :label="$t('select_date_*')"
            :max="dateValidation('max')"
            @change="fetchData"
          />
          <!-- :min="dateValidation('min')" -->
        </v-card>
        <v-card>
          <v-select
            v-model="customFilters.vehicle"
            filled
            dense
            :label="$t('select_vehicle')"
            :items="vehicleList"
            item-value="vehicle_id"
            item-text="vehicle_name"
            clearable
            style="max-width: 200px"
            @change="fetchData"
          />
        </v-card>
        <v-card>
          <v-select
            v-model="customFilters.job_type"
            filled
            dense
            :label="$t('job_type')"
            :items="['pick', 'drop', 'others']"
            clearable
            style="max-width: 200px"
            @change="fetchData"
          />
        </v-card>
      </template>
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div class="d-flex gap5">
          <refresh-button
            :is-loading="isLoading"
            @reload="fetchData"
          />

          <create-route-planning
            v-if="$admin.can('route-planning.add')"
            :tenant-id="options.tenant_id"
            @onSave="fetchData()"
          />
          <edit-route-planning
            v-if="editModal"
            :is-visible-dialog="editModal"
            :toggle-edit="toggleEditModal"
            :tenant-id="options.tenant_id"
            :item="selectedRow"
            @onSave="fetchData()"
          />
          <Modal
            v-if="isShowImg"
            title="picture"
            :is-visible="isShowImg"
            is-view
            :toggle="() => (isShowImg = false)"
          >
            <div>
              <v-img
                lazy-src="https://picsum.photos/id/11/10/6"
                max-height="100%"
                max-width="100%"
                :src="selectedImg"
              />
            </div>
          </Modal>
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    selectDate: () => import("@/components/base/SingleDatePicker.vue"),
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    CreateRoutePlanning: () => import("./dialogs/CreateRoutePlanning.vue"),
    EditRoutePlanning: () => import("./dialogs/EditRoutePlanning.vue"),
    //
    CopyContent: () => import("@/components/base/CopyContent.vue"),
    Modal: () => import("@/components/Modals/Modal.vue"),
    RefreshButton: () => import("@/components/base/RefreshButton.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},
      //
      customFilters: {
        date: this.getCurrentDate(),
      },
      isShowImg: false,
      selectedImg: "",
      headers: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: false,
          value: "id",
          width: "5%",
        },
        {
          text: this.$t("job_id"),
          align: "left",
          sortable: false,
          value: "job_id",
          width: "10%",
        },
        {
          text: this.$t("vehicle"),
          align: "left",
          sortable: false,
          value: "vehicle_name",
          // width: "15%",
        },
        {
          text: this.$t("shipment_id"),
          align: "left",
          sortable: false,
          value: "image_shipment_id",
          // width: "15%",
        },
        {
          text: this.$t("job_type"),
          align: "left",
          sortable: false,
          value: "job_type",
          // width: "15%",
        },
        {
          text: this.$t("client"),
          align: "left",
          sortable: false,
          value: "client_name",
          // width: "15%",
        },

        {
          text: this.$t("location"),
          align: "left",
          sortable: false,
          value: "location_name",
          // width: "15%",
        },
        {
          text: this.$t("map"),
          align: "left",
          sortable: false,
          value: "map",
          // width: "%",
        },

        {
          text: this.$t("stop"),
          align: "left",
          sortable: false,
          value: "stop_duration",
          // width: "10%",
        },
        {
          text: this.$t("time_window"),
          align: "left",
          sortable: false,
          value: "time_window",
          // width: "10%",
        },

        {
          text: this.$t("status"),
          align: "left",
          sortable: false,
          value: "status",
          // width: "%",
        },
        {
          text: this.$t("crew_chief"),
          align: "left",
          sortable: false,
          value: "crew_chief",
          // width: "%",
        },
        // {
        //   text: this.$t("start_at"),
        //   align: "left",
        //   sortable: false,
        //   value: "date",
        //   // width: "%",
        // },
        {
          text: this.$t("completed_at"),
          align: "left",
          sortable: false,
          value: "completed_at",
          // width: "%",
        },
        {
          text: this.$t("notes"),
          align: "left",
          sortable: false,
          value: "job_notes",
          width: "10%",
        },

        // {
        //   text: this.$t("actions"),
        //   align: "left",
        //   sortable: false,
        //   value: "actions",
        //   // width: "15%",
        // },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "routePlaning/getLoading",
      meta: "routePlaning/getMeta",
      list: "routePlaning/getList",
      //
      vehicleList: "routePlaning/getVehicleList",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
  },
  watch: {
    options() {
      this.getVehicles();
      this.getClient();
      this.getStartLocation();
      //
      this.fetchData();
    },
  },
  beforeDestroy() {
    // if (this.authUser.user_nature == "fleet") {
    //   this.options.tenant_id = this.authUser.tenant_id;
    // }
    const params = {
      name: "route_planning",
      // filters: this.options,
      // filters: {
      //   page: this.options.page,
      //   itemsPerPage: this.options.itemsPerPage,
      // },
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      await this.$store
        .dispatch("routePlaning/list", {
          ...this.options,
          ...this.customFilters,
        })
        .then(() => {
          if (this.options.page > (this.meta?.lastPage || 1)) {
            this.options.page = 1;
          }
        });
    },
    //
    async getVehicles() {
      const params = {
        ...this.customFilters,
        tenant_id: this.options.tenant_id,
      };
      await this.$store
        .dispatch("routePlaning/vehicleList", params)
        .then((res) => {
          this.options.vehicles = res.map((r) => ({
            ...r,
            ...r?.client_assignment[r.client_assignment.length - 1],
            return_to_start: r.return_to_start ? "true" : "false",
          }));
        });
    },
    async getClient() {
      let params = {
        product: this.options.product,
        tenant_id: this.options.tenant_id,
      };
      await this.$store.dispatch("routePlaning/clientList", params);
    },
    async getStartLocation() {
      let params = {
        product: this.options.product,
        tenant_id: this.options.tenant_id,
      };
      await this.$store.dispatch("routePlaning/startLocation", params);
    },
    //
    goToMapScreen(val) {
      return `/job-route/${val.vehicle}?date=${val.date}`;
    },
    showImage(row) {
      this.isShowImg = true;
      this.selectedImg = row.shipment_image;
    },
    //
    getCurrentDate() {
      const dt = new Date();
      const y = dt.getFullYear();
      let m = dt.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;

      let d = dt.getDate();
      d = d < 10 ? `0${d}` : d;

      return `${y}-${m}-${d}`;
    },
    // dateValidation(v) {
    //   const d = new Date();
    //   let day = d.getDate();
    //   if (v === "max") day += 1;
    //   day = day < 10 ? `0${day}` : day;

    //   let m = d.getMonth() + 1;
    //   m = m < 10 ? `0${m}` : m;

    //   const y = d.getFullYear();
    //   const value = `${y}-${m}-${day}`;
    //   return value;
    // },
    dateValidation(v) {
      const d = new Date();
      let day = d.getDate();

      if (v === "max") {
        day += 1;
        // Check if adding a day rolls over to the next month
        if (day > new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate()) {
          day = 1;
          d.setMonth(d.getMonth() + 1);
        }
      }

      // Format day and month with leading zeros
      day = day < 10 ? `0${day}` : day;
      const m = (d.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed
      const y = d.getFullYear();

      const value = `${y}-${m}-${day}`;
      return value;
    },
  },
};
</script>
<style lang="sass" scoped>
:deep .v-data-table__wrapper
  .v-data-table-header
    th
      padding: 0rem 0.5rem
      height: 30px !important
      span
        font-size: 11px !important
  tbody
    td
      padding: 0rem 0.5rem !important
      font-size: 11px !important
      .v-input, label
        font-size: 11px

.gap
  gap: 0.8rem

.open-link
  font-size: 12px

.fixed-text-box
  width: 6rem
  div
    overflow: hidden
    text-overflow: ellipsis
</style>
